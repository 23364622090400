<template>
  <div class="Breadcrumb-comp limitBox">
    <span>当前位置:</span>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
          v-for="(item,index) in props.list"
          :key="index"
          :to="{ path: props.list[index].routeName?props.list[index].routeName:'',query:{current:item.current}}"
      >
        {{ item.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { useStore }  from "vuex"
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "Breadcrumb",
  components: {},
  props: ["list"],
  setup(props, { attrs, slots, emit }) {
    const store = useStore()
    const state = Vue.reactive({

    })

    return {
      state,
      props
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../comment/comment";
.Breadcrumb-comp{
  display: flex;
  height: 45px;
  line-height: 45px;
  margin: 0 auto;
  /deep/.el-breadcrumb{
    line-height: 45px;
  }
  >span{
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  ///deep/.el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
  //  font-size: 14px;
  //  font-family: PingFangSC-Regular, PingFang SC;
  //  font-weight: 400;
  //  color: #333333;
  //}
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: #7F0404 ;
    font-weight: 600;
  }
  /deep/.el-breadcrumb__separator{
    color: #333333;
  }
}
</style>