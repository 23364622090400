<template>
  <div id="map" @click="OpenMap">
  </div>
  <div class="mapDia">
    <el-dialog
        v-model="state.dialogVisible"
        destroy-on-close
        :show-close="false"
        width="60%"
        top="100px"
        :before-close="handleClose"
    >
      <div class="close" @click="handleClose">X</div>
      <div class="mapDiaCon">
        <MapLocation
            :lng2="state.lng2"
            :lat2="state.lat2"
        ></MapLocation>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Vue from "vue";
import { ElMessage } from "element-plus";
import MapLocation from "../../views/Enterprise/MapLocation/MapLocation";
export default {
  props: ["longitude", "latitude"],
  components:{MapLocation},
  setup(props) {
    const state = Vue.reactive({
      dialogVisible:false,
      lng2:null,
      lat2:null
    })
    var map;
    const initMap = () => {
      map = new AMap.Map("map", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 10, //初始化地图层级
        center: [108.33, 22.84], //初始化地图中心点
      });
      if (props.longitude && props.latitude) {
        var marker = new AMap.Marker({
          icon: require("./1.png"),
          position: [props.longitude, props.latitude],
        });
        map.add(marker);
        map.setFitView(marker);
      }
      //   地址解析
      // let geocoder
      // AMap.plugin('AMap.Geocoder',function(){
      //     geocoder = new AMap.Geocoder()
      //     geocoder.getLocation( props.address , function(status, result) {
      //         if (status === 'complete'&&result.geocodes.length) {
      //             var lnglat = result.geocodes[0].location
      //             marker.setPosition(lnglat);
      //             map.add(marker);
      //             map.setFitView(marker);
      //         }else{
      //             ElMessage.error("查询地址失败！")
      //         }
      //     });
      // })
    };

    state.lng2=props.longitude;
    state.lat2=props.latitude;
    Vue.onMounted(() => {
      initMap();
    });
    const OpenMap=()=>{
      state.dialogVisible = true;
    }
    const handleClose=()=>{
      state.dialogVisible = false
    }
    return {
      state,
      OpenMap,
      handleClose
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0 !important;
  height: 600px !important;
  overflow: hidden;
}
.mapDiaCon{
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}
/deep/ .amap-logo{
  opacity: 0;
}
/deep/ .amap-copyright{
  opacity: 0;
}
</style>