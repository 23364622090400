<template>
  <div class="MapLocation">
    <div id="mapBox"></div>
    <div class="GotoMap" @click="GoToMap">前往高德地图</div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import * as Vue from "vue";
import router from "@/router";
import { ElMessage } from 'element-plus'

export default {
  name: "MapLocation",
  components: {},
  props: ["lng2", "lat2"],
  setup(props, {attrs, slots, emit}) {
    const store = useStore()
    const state = Vue.reactive({
      lat: null,
      lng: null,
      StartAddress: null,
      EndAddress: null
    })
    let map;
    const initMap = () => {
      map = new AMap.Map('mapBox', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 11, //初始化地图层级
        center: [116.397559, 39.89621], //初始化地图中心点
      });
    };
    /*路线*/
    const Driving = () => {
      var drivingOption = {
        policy: AMap.DrivingPolicy.LEAST_TIME, // 其它policy参数请参考 https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingPolicy
        ferry: 1, // 是否可以使用轮渡
        map: map
      }
      // 构造路线导航类
      var driving = new AMap.Driving(drivingOption)
      // 根据起终点经纬度规划驾车导航路线
      driving.search(new AMap.LngLat(state.lng, state.lat), new AMap.LngLat(props.lng2, props.lat2), function (status, result) {
        if (status === 'complete') {
          console.log(status, result)
        } else {
          console.log('获取驾车数据失败', result)
        }
      });
    }
    /*定位*/
    const GetAddress = () => {
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,//是否使用高精度定位，默认:true
          timeout: 5000,          //超过10秒后停止定位，默认：5s
          buttonPosition: 'RB',    //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: false,   //定位成功后是否自动调整地图视野到定位点
        });
        // map.addControl(geolocation);
        if(props.lat2===undefined || props.lng2===undefined){
          console.log("获取地址失败")
        }
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            onComplete(result)
          } else {
            onError(result)
          }
        });
      });

      //解析定位结果
      function onComplete(data) {
        console.log(data)
        state.lat = data.position.lat;
        state.lng = data.position.lng;
        state.StartAddress = data.formattedAddress;
        Driving();
      }

      //解析定位错误信息
      function onError(data) {
        console.log('定位失败', data);
        ElMessage({
          message: '定位失败,请查看地址权限或者更换浏览器！',
          type: 'error',
        })
      }
    }
    Vue.onMounted(() => {
      initMap();
      GetAddress();
    });
    /*前往高德地图*/
    const GoToMap = () => {
      LngLatToAddress();
    }
    const OpenLink=()=>{
      let str = `from[name]=${state.StartAddress}&from[lnglat]=${state.lng},${state.lat}&to[name]=${state.EndAddress}&to[lnglat]=${props.lng2},${props.lat2}&policy=1&type=car`;
      if(state.StartAddress===null || state.EndAddress===null){
        window.open('https://gaode.com/dir');
      }else{
        window.open('https://gaode.com/dir?'+str)
      }
    }
    const LngLatToAddress = () => {
      var geocoder = new AMap.Geocoder({
        city: "010", //城市设为北京，默认：“全国”
        radius: 1000 //范围，默认：500
      });
      var marker = new AMap.Marker();
      regeoCode();
      function regeoCode() {
        var lnglat = [props.lng2, props.lat2];
        map.add(marker);
        marker.setPosition(lnglat);
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            state.EndAddress = result.regeocode.formattedAddress;
            OpenLink();
          } else {
            OpenLink();
          }
        });
      }
    }
    return {
      state,
      GoToMap
    }
  }
}
</script>

<style lang="scss" scoped>
.MapLocation {
  width: 100%;
  height: 100%;
  position: relative;

  #mapBox {
    width: 100%;
    height: 100%;
  }
}

.GotoMap {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #7F0404;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

</style>