<template>
  <bg-page>
    <div class="Box">
      <div class="FirstBgImage">
        <div>
          <el-image
            v-if="state.EnterpriseAllInfo?.img"
            style="width: 100%; height: 400px"
            :src="state.EnterpriseAllInfo?.img"
            fit="cover"
          >
            <template #error>
              <div class="image-slot">
                <span>加载中</span>
              </div>
            </template>
          </el-image>
        </div>
        <div class="Logo">
          <div>
            <div>
              <el-image
                v-if="state.EnterpriseAllInfo.logo"
                style="width: 100px; height: 100px"
                :src="state.EnterpriseAllInfo.logo"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div>
              <p>{{ state.EnterpriseAllInfo.title }}</p>
              <p v-if="state.EnterpriseAllInfo.html.website">
                <a
                  :href="state.EnterpriseAllInfo.html.website"
                  target="_blank"
                  >{{ state.EnterpriseAllInfo.html.website }}</a
                >
              </p>
              <p v-else>暂无官网链接</p>
            </div>
          </div>
        </div>
      </div>
      <div class="limitBox EnterpriseBox">
        <div class="Left">
          <div class="EnterpriseInformation">
            <div>
              <el-image
                style="width: 100%; height: 100%"
                :src="state.EnterpriseAllInfo.license"
                :preview-src-list="[
                  state.EnterpriseAllInfo.license,
                ]"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div>
              <p class="EnterTitle">企业信息</p>
              <p>
                企业性质：<span>{{ state.natureOptions[state.EnterpriseAllInfo.nature || 0].label}}</span>
              </p>
              <p>
                行业类别：<span v-if="state.EnterpriseAllInfo.cate">{{
                  state.EnterpriseAllInfo.cate.name
                }}</span>
              </p>
              <p>
                法人代表：<span>{{
                  state.EnterpriseAllInfo.legal
                }}</span>
              </p>
              <p>
                注册资本：<span
                  >{{ state.EnterpriseAllInfo.capital }}万元</span
                >
              </p>
              <p>
                企业规模：<span>{{ state.scaleOptions[state.EnterpriseAllInfo.scale || 0].label }}</span>
              </p>
              <p>
                年报状况：<span>{{
                  state.EnterpriseAllInfo.report == 1 ? '异常' : '正常'
                }}</span>
              </p>
              <p>
                注册地址：<span v-if="state.EnterpriseAllInfo.city">{{
                  state.EnterpriseAllInfo.city.name
                }}</span>
              </p>
              <p>
                统一社会信用代码：<span>{{
                  state.EnterpriseAllInfo.number
                }}</span>
              </p>
            </div>
          </div>
          <div class="CompanyProfile">
            <div class="SecondTitleBox">
              <p><span class="SecondTitle">企业简介</span></p>
            </div>
            <div>
              <div>
                <p>成立时间</p>
                <p>{{ state.EnterpriseAllInfo.birth }}</p>
              </div>
              <div>
                <p>股东结构</p>
                <p>{{ state.EnterpriseAllInfo.html.shareholder }}</p>
              </div>
              <div>
                <p>所属行业</p>
                <p>{{ state.EnterpriseAllInfo.html.industry }}</p>
              </div>
              <div>
                <p>营业范围</p>
                <p v-html="state.EnterpriseAllInfo.html.scope"></p>
              </div>
            </div>
          </div>
          <div class="EnterpriseDemand">
            <div class="SecondTitleBox">
              <p><span class="SecondTitle">企业需求</span></p>
            </div>
            <div>
              <p>{{ state.EnterpriseAllInfo.html.demand }}</p>
            </div>
          </div>
          <div class="SupplementaryIntroduction">
            <div class="SecondTitleBox">
              <p><span class="SecondTitle">补充介绍</span></p>
            </div>
            <div>
              <p v-html="state.EnterpriseAllInfo.html.html"></p>
            </div>
          </div>
        </div>
        <div class="Right">
          <div class="contactInformation">
            <div><p class="EnterTitle">联系方式</p></div>
            <div>
              <div>
                <p>联系人</p>
                <p>{{ state.EnterpriseAllInfo.html.linkman }}</p>
              </div>
              <div>
                <p>业务电话</p>
                <p>{{ state.EnterpriseAllInfo.html.tell }}</p>
              </div>
              <div>
                <p>电子邮箱</p>
                <p>{{ state.EnterpriseAllInfo.html.email }}</p>
              </div>
              <div>
                <p>邮编</p>
                <p>{{ state.EnterpriseAllInfo.html.postal }}</p>
              </div>
              <div>
                <p>地址</p>
                <p>{{ state.EnterpriseAllInfo.html.address }}</p>
              </div>
              <div class="Map" v-if="state.EnterpriseAllInfo.html.location">
                <Map
                  :longitude="parseFloat(state.EnterpriseAllInfo.html.location.split(',')[0])"
                  :latitude="parseFloat(state.EnterpriseAllInfo.html.location.split(',')[1])"
                />
              </div>
            </div>
          </div>
          <div
            class="EnterpriseProducts"
            v-if="state.EnterpriseAllInfo.productList && state.EnterpriseAllInfo.productList?.length > 0"
          >
            <div><p class="EnterTitle">企业产品</p></div>
            <div class="Products">
              <div
                v-for="(item, index) in state.EnterpriseAllInfo.productList"
                :key="index"
                class="ProductsItem"
              >
                <div>
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="item.img"
                    fit="cover"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div>
                  <p>{{ item.name }}</p>
                  <p>{{ item.info }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Map from "@/components/Map/Map";
import api from "@/comment/request/api";
export default {
  name: "EnterpriseDetails",
  components: { Breadcrumb, Map },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      EnterpriseAllInfo: {html: {}},
      typeOptions: [
          {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "上规上统",
        },
        {
          value: 2,
          label: "文化龙头",
        },
        {
          value: 3,
          label: "文化百强",
        },
        {
          value: 4,
          label: "三企入桂",
        },
        {
          value: 5,
          label: "小微企业",
        },
      ],
      natureOptions: [
        {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "国有(控股)企业",
        },
        {
          value: 2,
          label: "民营(控股)企业",
        },
        {
          value: 3,
          label: "集体企业",
        },
        {
          value: 4,
          label: "外资企业",
        },
        {
          value: 5,
          label: "事业单位",
        },
      ],
      scaleOptions: [
        {
          value: 0,
          label: "10人以下",
        },
        {
          value: 1,
          label: "10-50人",
        },
        {
          value: 2,
          label: "50-200人",
        },
        {
          value: 3,
          label: "200-500人",
        },
        {
          value: 4,
          label: "500人以上",
        },
      ],
    });

    const GetDetailsInfo = () => {
      api.GetEnterpriseDetails(router.currentRoute._rawValue.query.id).success((res) => {
        state.EnterpriseAllInfo = res.data.data;
      });
    };
    GetDetailsInfo();
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  .FirstBgImage {
    position: relative;
    .Logo {
      width: 600px;
      height: 400px;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 0;
      right: 10%;
      backdrop-filter: blur(10px);
      > div:nth-child(1) {
        transform: translateY(40%);
        text-align: center;
        > div:nth-child(1) {
          /deep/.el-image {
            border-radius: 50%;
          }
        }
        > div:nth-child(2) {
          p:nth-child(1) {
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin-top: 20px;
          }
          p:nth-child(2) {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .EnterpriseBox {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .Left {
      width: 70%;
      .EnterpriseInformation {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: white;
        > div:nth-child(1) {
          width: 50%;
          height: 300px;
        }
        > div:nth-child(2) {
          width: calc(50% - 30px);
          p:not(p:nth-child(1)) {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;
            span {
              font-weight: 600;
            }
          }
          p:nth-child(1) {
            margin-top: 10px;
          }
        }
      }
      .CompanyProfile {
        background-color: white;
        margin-top: 40px;
        padding-bottom: 30px;
        padding-top: 30px;
        > div:nth-child(2) {
          padding: 0 30px;
          > div {
            p:nth-child(1) {
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-top: 20px;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 10px;
            }
          }
        }
      }
      .EnterpriseDemand {
        margin-top: 40px;
        padding-top: 30px;
        background-color: white;
        > div:nth-child(2) {
          padding: 0 30px 30px 30px;
          p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .SupplementaryIntroduction {
        margin-top: 40px;
        padding-top: 30px;
        background-color: white;
        > div:nth-child(2) {
          padding: 0 30px 30px 30px;
          p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            text-indent: 2em;
            line-height: 24px;
          }
          /deep/img {
            max-width: 100%;
            max-height: 100%;
            display: block;
          }
        }
      }
    }
    .Right {
      width: calc(30% - 40px);
      .contactInformation {
        padding-bottom: 40px;
        border-bottom: 1px solid #d9d9d9;
        > div:nth-child(1) {
          margin-top: 10px;
        }
        > div:nth-child(2) {
          > div {
            p:nth-child(1) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              margin-top: 10px;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-top: 5px;
            }
          }
          .Map {
            width: 100%;
            height: 200px;
            margin-top: 20px;
            //background-color: #EEE1CA;
          }
        }
      }
      .EnterpriseProducts {
        > div:nth-child(1) {
          margin-top: 40px;
          margin-bottom: 40px;
        }
        > div:nth-child(2) {
          width: 100%;
          height: 700px;
          overflow-y: auto;
          padding-right: 10px;
          .ProductsItem {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 10px;
            > div:nth-child(2) {
              width: calc(100% - 90px);
              p:nth-child(1) {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              p:nth-child(2) {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      /*滚动条样式*/
      .Products::-webkit-scrollbar-track {
        background-color: #d8d8d8;
      }

      .Products::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
      }

      .Products::-webkit-scrollbar-thumb {
        background-color: white;
      }
    }
    .EnterTitle {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7f0404;
    }
    .SecondTitleBox {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
a {
  color: white;
  text-decoration: none;
}
.DialogDetails {
  display: flex;
  justify-content: space-between;
  > div {
    width: calc(50% - 20px);
  }
  .tourDesc {
    height: 650px;
    padding-bottom: 20px;
    overflow-y: auto;
    padding-right: 20px;
    &::-webkit-scrollbar {
      width: 10px;
      height: 100%;
      background-color: #d8d8d8;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      height: 50px;
      background-color: #fff;
    }
  }
  > div:nth-child(2) {
    padding-right: 10px;
    // overflow-y: auto;
    p:nth-child(1) {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7f0404;
      margin-top: 40px;
    }
    p:nth-child(2) {
      margin-top: 20px;
    }
  }
  .Content {
    /deep/video {
      width: 100%;
    }
  }
}
.close {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  right: -40px;
  top: 0;
  background: #7f0404;
  cursor: pointer;
}
.desc {
  height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  /deep/img {
    display: none;
  }
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog__body {
  padding: 0;
  height: 800px;
  overflow: hidden;
}
</style>